import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import Grid from "@mui/material/Grid";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import {
    Checkbox, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, FormControl, InputLabel,
    ListItem, MenuItem, Select,
    TextField
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import {AddBoxOutlined, CheckOutlined, DeleteOutlined} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const Permissions = () => {
    const [roles, setRoles] = useState([])
    const [role, setRole] = useState({users:[], permissions:[]})
    const [permlist, setPermlist] = useState([])
    const [staff, setStaff] = useState([])
    const [modalAddRoleOpen, setModalAddRoleOpen] = useState(false)
    const [newRoleName, setNewRoleName] = useState('');
    const [modalAddMemberOpen, setModalAddMemberOpen] = useState(false)
    const [newMember, setNewMember] = useState(1);
    const permission = useSelector((state) => state.permissions.value)
    const project = useSelector((state) => state.session.project)
    const getData = async () => {
        setRoles(await Api(`admin/roles/${project}`))
        setPermlist(await Api(`admin/all-permissions/${project}`))
        let stafflist = []
        let mfslist = await Api(`admin/staff/list/mfs/1`)
        let tfslist = await Api(`admin/staff/list/tfs/1`)
        let discordlist = await Api(`admin/staff/list/moderation/1`)
        let hrlist = await Api(`admin/staff/list/hr/1`)
        let supportlist = await Api(`admin/staff/list/support/1`)
        let phlist = await Api(`admin/staff/list/ph/1`)
        for(let i of mfslist){
            stafflist.push({id:i.ID, name:`${i.Name}(MFS)`})
        }
        for(let i of tfslist){
            stafflist.push({id:i.ID, name:`${i.Name}(TFS)`})
        }
        for(let i of discordlist){
            stafflist.push({id:i.ID, name:`${i.Name}(MOD)`})
        }
        for(let i of hrlist){
            stafflist.push({id:i.ID, name:`${i.Name}(HR)`})
        }
        for(let i of supportlist){
            stafflist.push({id:i.ID, name:`${i.Name}(SUPPORT)`})
        }
        for(let i of phlist){
            stafflist.push({id:i.ID, name:`${i.Name}(PH)`})
        }
        setStaff(stafflist)
    }

    const checkedRolePermission = perm => {
        for(let i of role.permissions){
            if(i.permission.id === perm.id) return true;
        }
        return false;
    }
    const handleRoleNameChange = e => {
        let nrole = structuredClone(role)
        nrole.role_name = e.target.value;
        setRole(nrole);
    }
    const updateRoleName = () => {
        postApi(`admin/roles/set-name/${project}`,{id: role.id, name: role.role_name}).then(()=>{getData();});
    }

    const addNewRole = () => {
        postApi(`admin/roles/create-role/${project}`, {name: newRoleName}).then(()=>{
            getData().then(()=>{
                setModalAddRoleOpen(false);
            })
        })
    }
    const deleteRole = id => {
        postApi(`admin/roles/delete-role/${project}`, {id: id}).then(()=>{getData()})
    }
    const addRoleMember = e => {
        postApi(`admin/roles/add-member/${project}`, {role_id: role.id, member_id: newMember}).then(()=>{
            getData().then(()=>{
                setModalAddMemberOpen(false)
            })

        })
    }
    const deleteRoleMember = id => {
        postApi(`admin/roles/delete-member/${project}`, {role_id: role.id, member_id: id}).then(()=>{
            getData()
        })
    }
    const handleRolePermissionChange = perm => e => {
        let nrole = structuredClone(role)
        let found = false;
        for(let i in nrole.permissions){
            let rp = nrole.permissions[i]
            if(rp.permission_id === perm.id){
                found = true
                nrole.permissions.splice(i,1)
            }
        }
        if(!found){
            nrole.permissions.push({id:perm.id, permission_id:perm.id, permission: perm})
        }
        setRole(nrole)
    }

    const updateRolePermissions = () => {
        let permarr = [];
        for(let i of role.permissions){
            permarr.push(i.permission.id)
        }
        postApi(`admin/roles/update-permissions/${project}`, {id: role.id, permlist: permarr}).then(()=>{
            getData();
        });
    }

    useEffect(()=>{
        getData()
        //eslint-disable-next-line
    }, [])

    useEffect(()=>{
        if(roles.length > 0 && !role.id){ setRole(roles[0])}
        else {
            for (let r of roles) {
                if (r.id === role.id) {
                    setRole(r)
                }
            }
        }
        //eslint-disable-next-line
    }, [roles])

    return <Box>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Настройка ролевой модели</Typography>
                </Box>
            </Box>
        </Paper>
        {permission.indexOf('manage_permissions') > -1 &&
            <Grid container spacing={2} sx={{mt:1}}>
                <Grid item xs={4}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                            <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                                <Typography variant={'h5'} sx={{p:2}}>РОЛИ</Typography>
                            </Box>
                            {role.role_name > "" &&
                            <Box sx={{ flexGrow: 0, p:2 }}>
                                <TextField size={'small'} value={role.role_name} onChange={handleRoleNameChange}/>
                                <IconButton variant={'contained'} color={'success'} onClick={updateRoleName}>
                                    <CheckOutlined/>
                                </IconButton>
                            </Box>
                            }
                            <Box sx={{ flexGrow: 0, p:2 }}>
                                <IconButton variant={'outlined'} color={'primary'} onClick={()=>{setModalAddRoleOpen(true)}}>
                                    <AddBoxOutlined/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: '100%', overflow: 'hidden', mt:1 }}>
                        <List>
                            {roles.map((i,e)=><ListItem key={`role_${e}`}
                                                        secondaryAction={
                                                            i.id !== 1 && <IconButton
                                                                edge="end"
                                                                color={'error'}
                                                                onClick={()=>{deleteRole(i.id)}}
                                                            ><DeleteOutlined/></IconButton>
                                                        }
                            >
                                <ListItemButton onClick={()=>{setRole(i)}}>{i.role_name}</ListItemButton>
                            </ListItem>)}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                            <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                                <Typography variant={'h5'} sx={{p:2}}>УЧАСТНИКИ</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 0, p:2 }}>
                                <IconButton variant={'outlined'} color={'primary'} onClick={()=>{setModalAddMemberOpen(true)}}>
                                    <AddBoxOutlined/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: '100%', overflow: 'hidden', mt:1 }}>
                        <List>
                            {role.users.map((i,e)=><ListItem key={`user_${e}`}
                                                             secondaryAction={
                                                                 <IconButton
                                                                     edge="end"
                                                                     color={'error'}
                                                                     onClick={()=>{deleteRoleMember(i.user.ID)}}
                                                                 ><DeleteOutlined/></IconButton>
                                                             }
                            >
                                {i.user.Name} ({i.user.project.toUpperCase()})
                            </ListItem>)}
                        </List>
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                            <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                                <Typography variant={'h5'} sx={{p:2}}>ПРАВА РОЛИ</Typography>
                            </Box>
                            <Box sx={{ flexGrow: 0, p:2 }}>
                                <IconButton variant={'outlined'} color={'success'} onClick={updateRolePermissions}>
                                    <CheckOutlined/>
                                </IconButton>
                            </Box>
                        </Box>
                    </Paper>
                    <Paper sx={{ width: '100%', overflow: 'hidden', mt:1 }}>
                        <List>
                            {permlist.map((i,e)=><ListItem key={`perm_${e}`}
                                                           secondaryAction={
                                                               <Checkbox
                                                                   edge="end"
                                                                   checked={checkedRolePermission(i)}
                                                                   onChange={handleRolePermissionChange(i)}
                                                               />
                                                           }
                            >
                                {i.description}
                            </ListItem>)}
                        </List>
                    </Paper>
                </Grid>
                <Dialog
                    open={modalAddRoleOpen}
                    onClose={()=>{setModalAddRoleOpen(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Добавление новой роли"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={'span'} id="alert-dialog-description">
                            Введите название новой роли
                            <TextField fullWidth value={newRoleName} placeholder="Введите название новой роли" onChange={(e)=>{setNewRoleName(e.target.value)}}/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setModalAddRoleOpen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                        <Button onClick={addNewRole} color={'error'} variant={'contained'}>
                            Добавить
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={modalAddMemberOpen}
                    onClose={()=>{setModalAddMemberOpen(false)}}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Добавление участника к роли"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText component={'span'} id="alert-dialog-description">
                            <FormControl fullWidth sx={{mt: 2}}>
                                <InputLabel id="add_new_member">Выберите участника</InputLabel>
                                <Select
                                    labelId="add_new_member"
                                    id="new_member_select"
                                    value={newMember}
                                    label="Выберите участника"
                                    onChange={(i)=>{setNewMember(i.target.value)}}
                                >
                                    {staff.map((i,e)=><MenuItem key={'staff_'+e} value={i.id}>{i.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setModalAddMemberOpen(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                        <Button onClick={addRoleMember} color={'success'} variant={'contained'}>
                            Добавить
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        }
    </Box>
}
export default Permissions