import Typography from "@mui/material/Typography";
import * as React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import moment from "moment"
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {AddCircleOutlined, DeleteOutlined, RemoveCircleOutlined} from "@mui/icons-material";
import Grid from "@mui/material/Grid";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Whitelist = () => {
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);
    const [data, setData] = useState([])
    const [tempdata, setTempData] = useState([])
    const [addWLDialog, setAddWLDialog] = useState(false)
    const [newSteamID, setNewSteamID] = useState('')
    const [newNick, setNewNick] = useState('')
    const [addSingleDialog, setAddSingleDialog] = useState(false)
    const [deleteSingleDialog, setDeleteSingleDialog] = useState(false)
    const [singleData, setSingleData] = useState({id:0, steamid:''})
    const [reason, setReason] = useState('')
    const getData = async () => {
        setData(await Api(`whitelist/get-full`))
        setTempData(await Api(`whitelist/get-temp`))
    }
    const getColor = item => {return {color: (moment(item.last_onduty).diff(Date.now(), 'days') >= -90 ? 'green' : 'red')}};
    const addWL = () => {
        postApi(`admin/wl/add`,{steam_id: newSteamID, nick: newNick}).then(()=>{setAddWLDialog(false); getData()})
    }
    const addSignle = () => {
        postApi(`admin/wl/add-temp`,{steam_id: newSteamID, reason: reason}).then(()=>{setAddSingleDialog(false); getData()})
    }
    const changeReprovals = (id, action) => {
        postApi(`admin/wl/change-reproval-state`, {id:id, action: action}).then(()=>{getData()});
    }
    const deleteItem = id => {
        postApi(`admin/wl/delete-from-wl`,{id:id}).then(()=>{getData()});
    }
    const deleteSingle = () => {
        postApi(`admin/wl/del-temp/${singleData.id}`).then(()=>{setDeleteSingleDialog(false); getData()});
    }
    const showDeleteDg = item => {
        setSingleData(item)
        setDeleteSingleDialog(true)
    }
    //eslint-disable-next-line
    useEffect(()=>{
        if(project !== 'mfs'){
            window.location.href='/';
        }
        getData()
    },[project]);
    return <Grid container spacing={2}>
        <Grid item xs={8}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                    <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                        <Typography variant={'h5'} sx={{p:2}}>Белый лист диспетчеров {project.toUpperCase()}</Typography>
                    </Box>
                    <Box  sx={{ flexGrow: 0, p:2 }}>
                        {permissions.indexOf('add_whitelist') > -1 &&
                            <Button variant={'outlined'} color={'success'} sx={{mr:2}} onClick={()=>{setAddWLDialog(true)}}>Добавить разрешение</Button>
                        }
                        {permissions.indexOf('add_temporary_whitelist') > -1 &&
                            <Button variant={'outlined'} color={'info'} sx={{mr:2}} onClick={()=>{setAddSingleDialog(true)}}>Добавить разовое разрешение</Button>
                        }
                    </Box>
                </Box>
                <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
                    <Table stickyHeader aria-label="sticky table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Никнейм</TableCell>
                                <TableCell>SteamID</TableCell>
                                <TableCell>Дата добавления</TableCell>
                                <TableCell>Дата последнего дежурства</TableCell>
                                <TableCell>Выговоров</TableCell>
                                {permissions.indexOf('add_whitelist') &&
                                    <TableCell></TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((i,e) => <StyledTableRow key={`wl_data_${e}`}>
                                <TableCell>{i.nickname}</TableCell>
                                <TableCell>{i.steam_id}</TableCell>
                                <TableCell>{moment(i.inserted_date).format('DD.MM.YYYY')}</TableCell>
                                <TableCell style={getColor(i)}>{moment(i.last_onduty).format('DD.MM.YYYY')}</TableCell>
                                <TableCell>
                                    {permissions.indexOf('add_whitelist') > -1 &&
                                        <IconButton size={'small'} onClick={()=>{changeReprovals(i.id, 'decrease')}}
                                                    disabled={i.reprovals < 1}><RemoveCircleOutlined sx={{fontSize: '12pt'}}/></IconButton>
                                    }
                                    {i.reprovals}
                                    {permissions.indexOf('add_whitelist') > -1 &&
                                        <IconButton size={'small'} onClick={()=>{changeReprovals(i.id, 'increase')}}><AddCircleOutlined sx={{fontSize: '12pt'}}/></IconButton>
                                    }
                                </TableCell>
                                {permissions.indexOf('add_whitelist') > -1 &&
                                    <TableCell>
                                        <IconButton onClick={()=>{deleteItem(i.id)}}><DeleteOutlined/></IconButton>
                                    </TableCell>
                                }
                            </StyledTableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
                {permissions.indexOf('add_whitelist') > -1 &&
                    <Dialog
                        open={addWLDialog}
                        onClose={()=>{setAddWLDialog(false)}}
                        aria-labelledby="comment-dialog-title"
                        aria-describedby="comment-dialog-description"
                    >
                        <DialogTitle id="comment-dialog-title">
                            {"Добавление нового диспетчера в белый список "+project.toUpperCase()}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="comment-dialog-description">
                                <TextField value={newSteamID} fullWidth sx={{mt: 2}} placeholder={'STEAM_0:X:XXXXXXX'}
                                           onChange={(e)=>{setNewSteamID(e.target.value)}} label='STEAM_ID'/>
                                <TextField value={newNick} fullWidth sx={{mt: 2}} placeholder={'Никнейм'}
                                           onChange={(e)=>{setNewNick(e.target.value)}} label='Никнейм'/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setAddWLDialog(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                            <Button onClick={addWL} color={'success'} variant={'contained'}>Добавить</Button>
                        </DialogActions>
                    </Dialog>
                }
                {permissions.indexOf('add_temporary_whitelist') > -1 &&
                    <Dialog
                        open={addSingleDialog}
                        onClose={()=>{setAddSingleDialog(false)}}
                        aria-labelledby="comment-dialog-title"
                        aria-describedby="comment-dialog-description"
                    >
                        <DialogTitle id="comment-dialog-title">
                            {"Добавление разового разрешения "+project.toUpperCase()}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="comment-dialog-description">
                                <TextField value={newSteamID} fullWidth sx={{mt: 2}} placeholder={'STEAM_0:X:XXXXXXX'}
                                           onChange={(e)=>{setNewSteamID(e.target.value)}} label='STEAM_ID'/>
                                <TextField value={reason} fullWidth sx={{mt: 2}} placeholder={'Причина добавления'}
                                           onChange={(e)=>{setReason(e.target.value)}} label='Причина'/>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setAddSingleDialog(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                            <Button onClick={addSignle} color={'success'} variant={'contained'}>Добавить</Button>
                        </DialogActions>
                    </Dialog>
                }
                {permissions.indexOf('add_temporary_whitelist') > -1 &&
                    <Dialog
                        open={deleteSingleDialog}
                        onClose={()=>{setDeleteSingleDialog(false)}}
                        aria-labelledby="comment-dialog-title"
                        aria-describedby="comment-dialog-description"
                    >
                        <DialogTitle id="comment-dialog-title">
                            {"Удаление разового разрешения "+project.toUpperCase()}
                        </DialogTitle>
                        <DialogContent>
                            Вы хотите удалить временное разрешение для {singleData.steam_id}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={()=>{setDeleteSingleDialog(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                            <Button onClick={deleteSingle} color={'success'} variant={'contained'}>Удалить</Button>
                        </DialogActions>
                    </Dialog>
                }
            </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Typography variant={'h5'} sx={{p:2}}>Разовые разрешения</Typography>
            <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
                <Table stickyHeader aria-label="sticky table" size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>STEAM_ID</TableCell>
                            <TableCell>Причина</TableCell>
                            <TableCell>Дата добавления</TableCell>
                            {permissions.indexOf('add_temporary_whitelist') > -1 &&
                                <TableCell>...</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tempdata.length === 0 && <StyledTableRow>
                            <TableCell colSpan={4}>Нет действующих разрешений</TableCell>
                        </StyledTableRow>}
                        {tempdata.map((i,e) => <StyledTableRow key={`wl_data_${e}`}>
                            <TableCell>{i.steam_id}</TableCell>
                            <TableCell>{i.reason}</TableCell>
                            <TableCell>{moment(i.created_date).format('DD.MM.YYYY')}</TableCell>
                            {permissions.indexOf('add_temporary_whitelist') > -1 &&
                            <TableCell><IconButton size={'small'} onClick={()=>{showDeleteDg(i)}}><DeleteOutlined/></IconButton></TableCell>
                            }
                        </StyledTableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
            </Paper>
        </Grid>
    </Grid>


}
export default Whitelist