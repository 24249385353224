import React, {useEffect, useState} from "react";
import ServerCard from "../components/ServerCard";
import Api from "../Api";
import Grid from "@mui/material/Grid";
import {useSelector} from "react-redux";
import HrDashboard from "./HrDashboard";
const Monitoring = () => {
    const [servers, setServers] = useState([]);
    const project = useSelector(state => state.session.project);

    const getServers = async () => {
        setServers(await Api('monitoring/servers'))
    }
    useEffect(()=>{
        if(project !== 'mfs' && project !== 'tfs' && project !== 'ph') window.location.href='/staff'
        getServers()
        //eslint-disable-next-line
    },[project])
    return <div>
        <Grid container spacing={2}>
            {servers.filter(name=>name.indexOf(project) > -1).map((i,e)=><Grid item xs={12} md={6} key={'mon_'+i}><ServerCard server={i}  /></Grid>)}
        </Grid>
    </div>
}

const Dashboard = () => {
    const project = useSelector(state => state.session.project);
    if(project === 'hr'){
        return <HrDashboard/>
    }
    return <Monitoring/>

}

export default Dashboard;