import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Api from "../Api";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import Divider from "@mui/material/Divider";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useSelector} from "react-redux";


const HRStats = () => {
    const [weekly, setWeekly] = useState({projects: []})
    const [overall, setOverall] = useState({projects: []})
    const [employees, setEmployees] = useState({from_date:'loading...',to_date:'loading...',
        active_cv:[],accept_cv:[],decline_cv:[], averagetime_firstaction:[]})
    const [empnicks, setEmpnicks] = useState([])
    const permissions = useSelector(state => state.permissions.value)
    const getWeeklyStats = async () => {
        setWeekly(await Api('hr/stats/weekly-stats'))
        setOverall(await Api('hr/stats/overall-stats'))
        setEmployees(await Api(`hr/stats/stats-employees-hr`))
    }
    //eslint-disable-next-line
    useEffect(()=>{getWeeklyStats()}, [])
    useEffect(()=>{
        let nicks = [];
        for(let nick of employees.accept_cv){
            if(nicks.indexOf(Object.keys(nick)[0]) === -1){
                nicks.push(Object.keys(nick)[0])
            }
        }
        for(let nick of employees.decline_cv){
            if(nicks.indexOf(Object.keys(nick)[0]) === -1){
                nicks.push(Object.keys(nick)[0])
            }
        }
        for(let nick of employees.accept_cv){
            if(nicks.indexOf(Object.keys(nick)[0]) === -1){
                nicks.push(Object.keys(nick)[0])
            }
        }
        for(let nick of employees.averagetime_firstaction){
            if(nicks.indexOf(Object.keys(nick)[0]) === -1){
                nicks.push(Object.keys(nick)[0])
            }
        }
        setEmpnicks(nicks)
    }, [employees])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Статистика отдела персонала</Typography>
                </Box>
                <Box sx={{ flexGrow: 0, p:2 }}>
                </Box>
            </Box>
        </Paper>
        {permissions.indexOf('view_weekly_stats') > -1 &&
            <Grid container spacing={2} sx={{mt:1}}>
                <Grid item md={6}>
                    <Paper sx={{ width: '100%', p:2}}>
                        <Typography>Недельная статистика с {moment(weekly.from_date).format('DD.MM.YYYY HH:mm')}</Typography>
                        <Typography sx={{mt:1}} variant={'subtitle2'}>Обработка анкет</Typography>
                        <BarChart
                            width={800}
                            height={300}
                            data={weekly.projects}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >

                            <XAxis dataKey="project_name" name={"Проект"}/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="1 1" />
                            <Bar dataKey="created"  name={"Создано"} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                            <Bar dataKey="accepted" name={"Выполнено"} fill="#82ca9d" activeBar={<Rectangle fill="gold" stroke="purple" />} />
                            <Bar dataKey="declined" name={"Отклонено"} fill="#ff0a0d" activeBar={<Rectangle fill="gold" stroke="red" />} />
                        </BarChart>
                        <Divider/>
                        <Typography>Всего с начала недели:</Typography>
                        <Typography>Создано анкет: {weekly.total_week}</Typography>
                        <Table size={'small'}>
                            <TableHead>
                                <StyledTableRow>
                                    <TableCell>Направление</TableCell>
                                    <TableCell>Принято анкет</TableCell>
                                    <TableCell>Выполнено</TableCell>
                                    <TableCell>Отклонено</TableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {weekly.projects.map((i,e)=><StyledTableRow key={'ws_p_'+e}>
                                    <TableCell>{i.project_name}</TableCell>
                                    <TableCell>{i.created}</TableCell>
                                    <TableCell>{i.accepted}</TableCell>
                                    <TableCell>{i.declined}</TableCell>
                                </StyledTableRow>)}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item md={6}>
                    <Paper sx={{ width: '100%', p:2}}>
                        <Typography>Создано анкет за год c {moment(overall.from_date).format('DD.MM.YYYY HH:mm')}</Typography>
                        <Typography sx={{mt:1}} variant={'subtitle2'}>Помесячно</Typography>
                        <BarChart
                            width={800}
                            height={300}
                            data={overall.projects}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >

                            <XAxis dataKey="project_name" name={"Проект"}/>
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <CartesianGrid strokeDasharray="1 1" />
                            <Bar dataKey="1"  name={"Январь"} fill={"#4933fc"} activeBar={<Rectangle fill="gold" stroke="#4933fc" />} />
                            <Bar dataKey="2"  name={"Февраль"} fill={"#0077d8"} activeBar={<Rectangle fill="gold" stroke="#0077d8" />} />
                            <Bar dataKey="3"  name={"Март"} fill={"#32cabf"} activeBar={<Rectangle fill="gold" stroke="#32cabf" />} />
                            <Bar dataKey="4"  name={"Апрель"} fill={"#1dca8a"} activeBar={<Rectangle fill="gold" stroke="#1dca8a" />} />
                            <Bar dataKey="5"  name={"Май"} fill={"#18ca21"} activeBar={<Rectangle fill="gold" stroke="#18ca21" />} />
                            <Bar dataKey="6"  name={"Июнь"} fill={"#96d80a"} activeBar={<Rectangle fill="pink" stroke="#96d80a" />} />
                            <Bar dataKey="7"  name={"Июль"} fill={"#aec970"} activeBar={<Rectangle fill="gold" stroke="#aec970" />} />
                            <Bar dataKey="8"  name={"Август"} fill={"#d0bc52"} activeBar={<Rectangle fill="gold" stroke="#d0bc52" />} />
                            <Bar dataKey="9"  name={"Сентябрь"} fill={"#eead38"} activeBar={<Rectangle fill="gold" stroke="#eead38" />} />
                            <Bar dataKey="10" name={"Октябрь"} fill={"#ff6437"} activeBar={<Rectangle fill="gold" stroke="#ff6437" />} />
                            <Bar dataKey="11" name={"Ноябрь"} fill={"#ff4423"} activeBar={<Rectangle fill="gold" stroke="#ff4423" />} />
                            <Bar dataKey="12" name={"Декабрь"} fill={"#ff0265"} activeBar={<Rectangle fill="gold" stroke="#ff0265" />} />
                        </BarChart>
                    </Paper>
                    <Paper sx={{ width: '100%', p:2, mt:2}}>
                        <Typography variant={'h4'} align={'center'}>В РАБОТЕ {overall.inwork} АНКЕТ</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant={'h6'} align={'center'}>Индивидуальная статистика ({employees.from_date} - {employees.to_date})</Typography>
                    <Table size={'small'}>
                        <TableHead>
                            <StyledTableRow>
                                <TableCell>Никнейм</TableCell>
                                <TableCell>Активных анкет</TableCell>
                                <TableCell>Принято за месяц</TableCell>
                                <TableCell>Отклонено за месяц</TableCell>
                                <TableCell>Среднее время первого действия</TableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {empnicks.map((i,e)=>{
                                return <StyledTableRow key = {`row_key_${e}`}>
                                    <TableCell>{i}</TableCell>
                                    <TableCell>{employees.active_cv.filter((it)=>{
                                        return Object.keys(it)[0] === i
                                    })[0][i]}</TableCell>
                                    <TableCell>{employees.accept_cv.filter((it)=>{
                                        return Object.keys(it)[0] === i
                                    })[0][i]}</TableCell>
                                    <TableCell>{employees.decline_cv.filter((it)=>{
                                        return Object.keys(it)[0] === i
                                    })[0][i]}</TableCell>
                                    <TableCell>{employees.averagetime_firstaction.filter((it)=>{
                                        return Object.keys(it)[0] === i
                                    })[0][i]}</TableCell>
                                </StyledTableRow>
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        }
        {permissions.indexOf('view_weekly_stats') === -1 && <Box>
            <Typography color={'error'}>У вас нет доступа к просмотру статистики ОП</Typography>
        </Box>}

        </div>
}
export default HRStats