import * as React from 'react';
import dayjs from 'dayjs';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import {
    ChevronLeft,
    ChevronRight,
    DeleteOutlined,
    Groups,
    RemoveCircle, SaveAltOutlined,
    SaveOutlined
} from "@mui/icons-material";
import Api, {postApi} from "../Api";
import {Chip, Modal, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Autocomplete from '@mui/material/Autocomplete';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {useSelector} from "react-redux";

const MonthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь']

const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    minHeight: '50vh',
    maxHeight: '90vh',
    overflowY: 'auto'
};

const Calendar = () => {
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [data, setData] = useState([])
    const [staff, setStaff] = useState([])
    const [dayModalOpened, setDayModalOpened] = useState(false)
    const [infoDay, setInfoDay] = useState(0);
    const [newEventName, setNewEventName] = useState('')
    const [newEventBody, setNewEventBody] = useState('')
    const [addStaf, setAddStaff] = useState([])
    const [addTime, setAddTime] = useState(dayjs("1970-01-01T00:00"))
    const [groupeditorOpened, setGroupeditorOpened] = useState(false)
    const [groups, setGroups] = useState([])
    const [newgrpname, setNewGrpName] = useState('')
    const [newuser, setNewUser] = useState('')
    const permissions = useSelector(state => state.permissions.value);
    const project = useSelector(state => state.session.project);
    let firstDay = new Date(currentYear, currentMonth, 1).getDay()
    let lastDay = new Date(currentYear, currentMonth+1, 0).getDate();
    if(firstDay === 0){
        firstDay = 7;
    }

    const getData = async () => {
        setData(await Api(`admin/calendar/my/${currentMonth + 1}/${currentYear}`));
        setStaff(await Api(`admin/staff/list-ids`))
        setGroups(await Api(`admin/calendar/groups`))
    }

    const prevMonth = () => {
        let pm = new Date(currentYear,currentMonth,0)
        setCurrentMonth(pm.getMonth())
        setCurrentYear(pm.getFullYear())
    }
    const nextMonth = () => {
        let nm = new Date(currentYear,currentMonth+1,1)
        setCurrentMonth(nm.getMonth())
        setCurrentYear(nm.getFullYear())
    }

    const checkToday = d => {
        if(d === new Date().getDate() && currentMonth === new Date().getMonth() && currentYear === new Date().getFullYear()){
            return 'today'
        }
        return ''
    }

    const deleteData = id => {
        postApi(`admin/calendar/delete/${id}`).then(()=>{getData(); setDayModalOpened(false)});
    }

    const createEvent = () => {
        postApi(`admin/calendar/create`,{
            "event_date": `${currentYear}-${currentMonth+1}-${infoDay} ${addTime.hour()}:${addTime.minute()}`,
            "event_name": newEventName,
            "event_body": newEventBody,
            "addstaff":  addStaf
        }).then(()=>{
            setNewEventBody('');
            setNewEventName('');
            setAddStaff([])
            getData();
            setDayModalOpened(false)
        });
    }

    const openModal = d => {
        setInfoDay(d);
        setDayModalOpened(true);
    }
    const resolveStaff = sid => {
        for(let s of staff){
            if(s.id === sid){
                return s.name
            }
        }
        return sid
    }

    const showGroupEditor = () => {
        setGroupeditorOpened(true)
    }

    const showData = (d, detailed) => {
        let result = []
        for(let i of data){
            let datadate = new Date(i.event_date).getDate()
            let datamonth = new Date(i.event_date).getMonth()
            if(datadate === d && datamonth === currentMonth) {
               result.push({id: i.id, steamid: i.steamid, name: i.event_name, body: i.event_body,
                   time: new Date(i.event_date).toLocaleTimeString(),
                   shared: i.steamid !== localStorage.myid, users:i.users})
            }
        }
        if(result){
            return <Table sx={{padding: 0}}>
                {detailed &&
                <TableHead>
                    <TableRow>
                        <TableCell>Автор</TableCell>
                        <TableCell>Время</TableCell>
                        <TableCell>Событие</TableCell>
                        <TableCell>Описание</TableCell>
                        <TableCell>Участники</TableCell>
                        <TableCell>Удалить</TableCell>
                    </TableRow>
                </TableHead>
                }
                <TableBody>
                    {result.map((ii,ee)=><TableRow key={'r_c_'+d+'_'+ee}>
                        {detailed && <TableCell>{resolveStaff(ii.steamid)}</TableCell>}
                        {detailed && <TableCell>{ii.time}</TableCell>}
                        <TableCell sx={{padding:'2px', fontSize: '9pt'}} title={ii.body}
                                   className={ii.shared ? 'calendar-shared':''}
                        >{ii.name}</TableCell>
                        {detailed && <TableCell>{ii.body}</TableCell>}
                        {detailed && <TableCell>{ii.users.map((e)=>resolveStaff(e.steam_id)).toString()}</TableCell>}
                        {detailed && !ii.shared && <TableCell><IconButton onClick={()=>{deleteData(ii.id)}}><RemoveCircle/></IconButton></TableCell>}

                    </TableRow>)}
                </TableBody>
            </Table>
        }
        return []
    }

    const renderRows = () => {
        let cells = [];
        let rows = []
        for(let c = 1; c < firstDay; c++){
            cells.push('')
        }
        for(let d = 1; d<=lastDay; d++){
            cells.push(d)
            if((d+firstDay-1)%7 === 0){
                rows.push(cells)
                cells = []
            }
        }
        if(cells.length > 0) {
            for (let c = cells.length; c<7; c++){
                cells.push('')
            }
            rows.push(cells)
        }
        return rows.map((i,e)=><TableRow key={'t_'+e}>
            {i.map((ii,ee)=><TableCell key={'c_'+e+'_'+ee}
                                       sx={{border: '1px solid gray', padding: 0, verticalAlign: 'top', width: '10%'}}
                                       className={checkToday(ii)}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell onClick={()=>{openModal(ii)}} className={ii>0? 'calendar-daycell' : 'empty-daycell'}>{ii}</TableCell>
                            <TableCell className={'calendar-datacell'}>{showData(ii, false)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableCell>)}
        </TableRow>);
    }

    useEffect(() => {
        getData()
    }, [currentMonth]);

    return <div>
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box sx={{width: '100%', textAlign: 'center'}} component={'div'}>
            <Typography variant={'h5'} sx={{p:2, width: '95%', float: 'left'}}>
                <IconButton onClick={prevMonth}>
                    <ChevronLeft/>
                </IconButton>
                Календарь сотрудника на {MonthNames[currentMonth]} {currentYear}
                <IconButton onClick={nextMonth}>
                    <ChevronRight/>
                </IconButton>
            </Typography>
            {permissions.indexOf('manage_calendar_group') > -1 &&
            <IconButton onClick={showGroupEditor}>
                <Groups/>
            </IconButton>
            }
        </Box>
        </Paper>
        <Paper sx={{ width: '100%', height: '80vh', marginTop: '1vh', overflow: 'auto', padding: 2 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Пн</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Вт</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Ср</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Чт</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Пт</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Сб</TableCell>
                        <TableCell align={'center'} sx={{border: '1px solid gray'}}>Вс</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {renderRows()}
                </TableBody>
            </Table>
        </Paper>

        <Modal open={dayModalOpened}
               onClose={()=>{setDayModalOpened(false)}}>
            <Box sx={ModalStyle}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography variant={'h5'}>{infoDay} {MonthNames[currentMonth]} {currentYear}</Typography>
                <hr/>
                <Box sx={{minHeight: '30vh'}}>
                        {showData(infoDay, true)}
                </Box>
                <hr/>
                <Typography>Добавление нового события в эту дату</Typography>
                <TimePicker ampm={false} value={addTime} onChange={(e)=>setAddTime(e)}/>
                <TextField placeholder={'Название события'}
                           value={newEventName}
                           sx={{ marginLeft: 1, width:'20%'}}
                           onChange={(i)=>{setNewEventName(i.target.value)}}/>
                <TextField placeholder={'Описание события'}
                           value={newEventBody}
                           sx={{width: '50%', marginLeft: 1}}
                           onChange={(i)=>{setNewEventBody(i.target.value)}}/>
                <Autocomplete
                    multiple
                    id="tags-standard"
                    options={staff.concat(groups.map((e)=>{return {name:`--- ${e.group_name} ---`, id:e.group_name}}))}
                    getOptionLabel={(option) => option.name}
                    sx={{marginTop: 2}}
                    onChange = {(e,v)=>{
                        setAddStaff(v.map((i)=>i.id))}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            label="Добавить к событию"
                            placeholder="Никнейм"
                        />
                    )}
                />
                <Button variant={'contained'} color='primary' onClick={createEvent} size={'large'} sx={{marginTop: 2}}>Создать</Button>
                </LocalizationProvider>
            </Box>
        </Modal>
        <Modal open={groupeditorOpened}
               onClose={()=>{setGroupeditorOpened(false)}}>
            <Box sx={ModalStyle}>
                <Typography variant={'h5'}>Редактор групп пользователей календаря</Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Название группы</TableCell>
                            <TableCell>Участники группы</TableCell>
                            <TableCell>Добавление</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {groups.map((i,e)=><TableRow key={'g_edit_row'+e}>
                            <TableCell>
                                {i.group_name}
                                <IconButton onClick={async ()=>{
                                            await postApi(`admin/calendar/remove-group/${project}`,
                                                    {group_name:i.group_name}
                                                  ).then(getData)}
                                }><DeleteOutlined/></IconButton>
                            </TableCell>
                            <TableCell>{i.users.map((e,c)=><Chip key={'g_c_'+i.id+'_'+c}
                                     label={resolveStaff(e.steam_id)}
                                     onDelete={async ()=>{
                                         await postApi(`admin/calendar/remove-user-from-group/${project}`,{
                                             group_name: i.group_name,
                                             steamid: e.steam_id
                                         }).then(()=>{getData()})
                                     }}
                                                                        />
                                                    )
                                        }
                            </TableCell>
                            <TableCell sx={{width: '20%'}}>
                                <Autocomplete
                                    renderInput={(p)=><TextField {...p}/>}
                                    getOptionLabel={(option) => option.name}
                                    options={staff}
                                    isOptionEqualToValue={(option, value)=> {return option.name === value} }
                                    onChange={(e, v)=>{v && setNewUser(v.id)}}
                                />
                                <IconButton onClick={async ()=>{
                                    await postApi(`admin/calendar/add-user-in-group/${project}`,{
                                        group_name: i.group_name,
                                        steamid: newuser
                                    }).then(()=>{setNewUser(''); getData()})
                                }}>
                                    <SaveAltOutlined/>
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
                <Box sx={{marginTop: 2, width: '100%'}}>
                <TextField sx={{width: '40%'}}
                           placeholder={'Название новой группы'}
                           value={newgrpname}
                           onChange={e=>{setNewGrpName(e.target.value)}}
                />
                <IconButton size={'large'}
                    onClick={async ()=>{
                        await postApi(`admin/calendar/create-group/${project}`,{
                            group_name: newgrpname
                        }).then(()=>{getData(); setNewGrpName('')})
                    }}
                ><SaveOutlined/></IconButton>
                </Box>
            </Box>
        </Modal>
    </div>
}

export default Calendar