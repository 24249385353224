import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {AddBoxOutlined} from "@mui/icons-material";
import {useEffect, useState} from "react";
import Api, {postApi} from "../Api";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Switch, TextField} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Staff = () => {
    const [data, setData] = useState([])
    const [ranks, setRanks] = useState([])
    const [active, setActive] = useState(true)
    const [addDialog, setAddDialog] = useState(false)
    const [newSteamID, setNewSteamID] = useState('')
    const [newNick, setNewNick] = useState('')
    const project = useSelector(state => state.session.project);
    const permissions = useSelector(state => state.permissions.value);
    const getData = async () => {
        setData(await Api(`admin/staff/list/${project}/${active?1:0}`))
        setRanks(await Api('admin/staff/ranks/'+project))
    }
    //eslint-disable-next-line
    useEffect(()=>{getData()},[project, active])

    const resolveRank = id => {
        let r = ranks.filter(i=>i.RankID === id)
        if(r[0])
            return r[0].Name
        return "N/A"
    }
    const addAdmin = () => {
        postApi(`admin/staff/new/${project}`, {steam_id: newSteamID, nick: newNick}).then(()=>{
            getData();
            setAddDialog(false);
        });
    }
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography component={'div'} variant={'h5'} sx={{p:2}}>Список администраторов направления {project.toUpperCase()}
                        <span style={{marginLeft: 100, fontSize: '9pt'}}>Бывшие <Switch checked={active} onChange={()=>{setActive(!active)}}/> Текущие</span>
                    </Typography>
                </Box>
                {permissions.indexOf('add_admin') > -1 &&
                <Box sx={{ flexGrow: 0, p:2 }}>
                    <IconButton
                        color="inherit"
                        edge="start"
                        sx={{
                            marginRight: 0,
                        }}
                        onClick={()=>{setAddDialog(true)}}
                    >
                        <AddBoxOutlined/>
                    </IconButton>
                </Box>
                }
            </Box>
            <TableContainer sx={{ maxHeight: 'calc(90vh - 80px)'}}>
                <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Идент</TableCell>
                            <TableCell>Никнейм</TableCell>
                            <TableCell>Реальное имя</TableCell>
                            <TableCell>STEAM ID</TableCell>
                            <TableCell>Позиция</TableCell>
                            <TableCell>Специфика</TableCell>
                            <TableCell>Выговоров</TableCell>
                            {!active &&
                                <TableCell>Причина</TableCell>
                            }
                            <TableCell>Профиль</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((i,e)=><StyledTableRow key={'datarow_'+project+'_'+e}>
                            <TableCell>{i.ID}</TableCell>
                            <TableCell>{i.Name}</TableCell>
                            <TableCell>
                                <Link to={i.vk_id}
                                      style={{color: 'inherit', textDecoration: 'underline dotted' }}
                                      target={'_blank'}

                                >
                                            {i.real_name}
                                </Link>
                            </TableCell>
                            <TableCell>{i.SteamID}</TableCell>
                            <TableCell>{resolveRank(i.Rank)}</TableCell>
                            <TableCell>{i.description}</TableCell>
                            <TableCell>{i.reprovals}</TableCell>
                                {!active &&
                                    <TableCell>{i.remove_reason}</TableCell>
                                }
                                <TableCell>
                                    <Link to={'/profile/'+i.SteamID} style={{color: 'inherit'}}>
                                        <IconButton>
                                            <AccountBoxIcon />
                                        </IconButton>
                                    </Link>
                                </TableCell>
                        </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {permissions.indexOf('add_admin') > -1 &&
                <Dialog
                    open={addDialog}
                    onClose={()=>{setAddDialog(false)}}
                    aria-labelledby="comment-dialog-title"
                    aria-describedby="comment-dialog-description"
                >
                    <DialogTitle id="comment-dialog-title">
                        {"Добавление нового администратора в проект "+project.toUpperCase()}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="comment-dialog-description">
                            <TextField value={newSteamID} fullWidth sx={{mt: 2}} placeholder={'STEAM_0:X:XXXXXXX'}
                                       onChange={(e)=>{setNewSteamID(e.target.value)}} label='STEAM_ID'/>
                            <TextField value={newNick} fullWidth sx={{mt: 2}} placeholder={'Никнейм'}
                                       onChange={(e)=>{setNewNick(e.target.value)}} label='Никнейм'/>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=>{setAddDialog(false)}} color={'info'} variant={'contained'} autoFocus>Я передумал</Button>
                        <Button onClick={addAdmin} color={'success'} variant={'contained'}>Добавить</Button>
                    </DialogActions>
                </Dialog>
            }
        </Paper>
    );
}

export default Staff