import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import Api from "../Api";
import {Grid, TableCell} from "@mui/material";
import moment from "moment";
import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

const SupportStats = () => {
    const [overall, setOverall] = useState({
        "from_date": "loading...",
        "to_date": "loading...",
        "total_tickets": 0,
        "total_questions": 0,
        "total_report": 0,
        "total_offer": 0,
        "total_error": 0,
        "total_other": 0,
        "total_noprefix": 0,
        "category_mfs_tickets": 0,
        "category_mfs_report": 0,
        "category_tfs_tickets": 0,
        "category_tfs_report": 0,
        "category_discord_tickets": 0,
        "category_discord_report": 0,
        "category_hr_tickets": 0,
        "category_hr_report": 0,
        "category_web_tickets": 0,
        "category_tech_tickets": 0,
        "category_other_tickets": 0,
        "average_one": "Нет данных",
        "average_ticket": "Нет данных",
        "average_report": "Нет данных",
        "median_one": "Нет данных",
        "median_ticket": "Нет данных",
        "median_report": "Нет данных"
    })
    const [employees, setEmployees] = useState({
        "from_date": "loading",
        "to_date": "loading",
        "tickets": []
    });
    const permissions = useSelector(state => state.permissions.value)

    const getWeeklyStats = async () => {
        setOverall(await Api('support/get-statistics'))
        setEmployees(await Api(`support/stats-employees`))
    }
    //eslint-disable-next-line
    useEffect(()=>{getWeeklyStats()}, [])
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>Статистика службы поддержки</Typography>
                </Box>
                <Box sx={{ flexGrow: 0, p:2 }}>
                </Box>
            </Box>
            {permissions.indexOf('view_stats_support') > -1 &&
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <Paper sx={{ width: '100%', p:2}}>
                            <Typography>Статистика по тикетам с {moment(overall.from_date).format('DD.MM.YYYY HH:mm')} по {moment(overall.to_date).format('DD.MM.YYYY HH:mm')}</Typography>
                            <Table size={'small'}>
                                <TableBody>
                                    <StyledTableRow>
                                        <TableCell>Всего тикетов:</TableCell>
                                        <TableCell>{overall.total_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего вопросов:</TableCell>
                                        <TableCell>{overall.total_questions}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего жалоб:</TableCell>
                                        <TableCell>{overall.total_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего предложений:</TableCell>
                                        <TableCell>{overall.total_offer}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего ошибок:</TableCell>
                                        <TableCell>{overall.total_error}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего &quot;прочее&quot;:</TableCell>
                                        <TableCell>{overall.total_other}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Всего без префикса:</TableCell>
                                        <TableCell>{overall.total_noprefix}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell colSpan={2}>------------</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов MFS:</TableCell>
                                        <TableCell>{overall.category_mfs_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Жалоб MFS:</TableCell>
                                        <TableCell>{overall.category_mfs_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов TFS:</TableCell>
                                        <TableCell>{overall.category_tfs_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Жалоб TFS:</TableCell>
                                        <TableCell>{overall.category_tfs_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов DISCORD:</TableCell>
                                        <TableCell>{overall.category_discord_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Жалоб DISCORD:</TableCell>
                                        <TableCell>{overall.category_discord_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов HR:</TableCell>
                                        <TableCell>{overall.category_hr_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Жалоб HR:</TableCell>
                                        <TableCell>{overall.category_hr_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов WEB:</TableCell>
                                        <TableCell>{overall.category_web_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов TECH:</TableCell>
                                        <TableCell>{overall.category_tech_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Тикетов &quot;Прочее&quot;:</TableCell>
                                        <TableCell>{overall.category_other_tickets}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell colSpan={2}>------------</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Среднее время первого ответа:</TableCell>
                                        <TableCell>{overall.average_one}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Среднее время ответов на тикеты:</TableCell>
                                        <TableCell>{overall.average_ticket}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Среднее время ответов на жалобы:</TableCell>
                                        <TableCell>{overall.average_report}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Медианное время первого ответа:</TableCell>
                                        <TableCell>{overall.median_one}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Медианное время ответов на тикеты:</TableCell>
                                        <TableCell>{overall.median_ticket}</TableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <TableCell>Медианное время ответов на жалобы:</TableCell>
                                        <TableCell>{overall.median_report}</TableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                    <Grid item md={6}>
                        <Paper sx={{ width: '100%', p:2}}>
                            <Typography>Персональная статистика по тикетам с {moment(employees.from_date).format('DD.MM.YYYY HH:mm')} по {moment(employees.to_date).format('DD.MM.YYYY HH:mm')}</Typography>
                            <Table size={'small'}>
                                <TableHead>
                                    <StyledTableRow>
                                        <TableCell>Никнейм</TableCell>
                                        <TableCell>Тикетов обработано</TableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {employees.tickets.map((i,e)=><StyledTableRow key={'emp_stat_'+e}>
                                        <TableCell>{Object.keys(i)[0]}</TableCell>
                                        <TableCell>{Object.values(i)[0]}</TableCell>
                                    </StyledTableRow>)}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                </Grid>
            }
            {permissions.indexOf('view_stats_support') === -1 && <Box>
                <Typography color={'error'}>У вас нет доступа к просмотру статистики ОП</Typography>
            </Box>}
        </Paper>
    </div>
}
export default SupportStats;