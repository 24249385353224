import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Link,
    MenuItem,
    Pagination,
    Select
} from "@mui/material";
import {useEffect, useState} from "react";
import Api from "../Api";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {styled} from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import moment from "moment";

const ListCvs = ({checkstatus}) => {
    const [activeProject, setActiveProject] = useState('mfs')
    const [projects, setProjects] = useState([{shortname:'mfs'}])
    const [cv, setCv] = useState([])
    const [vac, setVac] = useState([{question:[]}])
    const [questions, setQuestions] = useState([])
    const [expanded, setExpanded] = useState(false);
    const [page, setPage] = useState(1);

    const handleChange = (panel, cvi) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getProjects = async () => {
        setProjects(await Api('hr/project/get-all-project'))
    }
    const getCv = async () => {
        setCv(await Api(`hr/cv/get-cv-project/${activeProject}`))
    }
    const getQuestions = async () => {
        setQuestions(await Api('hr/question/get-questions'))
    }
    const getVacancies = async () => {
        setVac(await Api(`hr/vacancy/get-vacancy-short`))
    }
    const resolveVacName = id => {
        for(let v of vac){
            if(v.id === id) return v.name;
        }
        return 'н/д';
    }
    //eslint-disable-next-line
    useEffect(()=>{getProjects(); getQuestions(); getVacancies() },[])
    //eslint-disable-next-line
    useEffect(()=>{getCv();},[activeProject])

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const resolveQuestionName = id =>{
        for(let q of questions){ if (q.id === id) return q.text_cv}

    }
    const resolveQuestionShortName = id => {
        for(let q of questions){ if (q.id === id) return q.shortname}
    }
    const resolveQuestionType = id => {
        for(let q of questions){ if (q.id === id) return q.type}
    }

    const resolveCvDetails = (cvData) => {
        if(!cvData.hasOwnProperty('data_cv')){return <div/>}
        let vacancy = {question:"[]"}
        for(let v of vac){ if(v.id === cvData.vacancy_id){vacancy = v; break}}
        let v_questions = JSON.parse(vacancy.question)
        let jsdata = {}
        try {
            jsdata = JSON.parse(cvData.data_cv.replace(/\\\(/, '(').replace(/\\'/, "'").replace(/\\#/,'#'));
        }catch{
            console.log("нет данных");
        }
        return <Box sx={{p:2}}>
            <Table size={'small'}>
                <TableBody>
                    <StyledTableRow>
                        <TableCell colSpan={2} align={'center'} sx={{color: 'yellow'}}>
                            Служебная информация
                        </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Nickname</TableCell>
                        <TableCell>{jsdata.nickname}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>STEAM LINK</TableCell>
                        <TableCell><Link href={jsdata.steam} target={'_blank'}>{jsdata.steam}</Link></TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Присоединился к проекту</TableCell>
                        <TableCell>{jsdata.created || "Нет данных"}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Наличие в ЧС</TableCell>
                        <TableCell>{jsdata.blacklist}</TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <TableCell>Подал анкету (раз):</TableCell>
                        <TableCell>{jsdata.amount || 1}</TableCell>
                    </StyledTableRow>
                    {v_questions.map((i,e)=>{
                        let qtype = resolveQuestionType(i);
                        let qname = resolveQuestionName(i)
                        let qa = jsdata[resolveQuestionShortName(i)]
                        return <StyledTableRow key={'q_'+e}>
                            {qtype ===5 &&
                                <TableCell colSpan={2} align={'center'} sx={{color: 'yellow'}}>
                                    {qname}
                                </TableCell>
                            }
                            {qtype !== 5 &&
                                <TableCell>{qname}</TableCell>
                            }
                            {qtype !==5 &&
                                <TableCell>{qa && qa.indexOf('http') > -1 ? <Link href={qa} target={'_blank'}>{qa}</Link> :qa}</TableCell>
                            }
                        </StyledTableRow>}
                    )}
                    <StyledTableRow>
                        <TableCell>Комментарий HR (причина отклонения)</TableCell>
                        <TableCell sx={{color: 'red'}}>{cvData.hr_comments}</TableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </Box>
    }

    return <div>
        <Paper sx={{ width: '100%', overflow: 'auto' }}>
            <Box sx={{display: 'flex', width: '100%'}} component={'div'}>
                <Box sx={{ marginLeft: 2, flexGrow: 1, display: { md: 'flex' } }}>
                    <Typography variant={'h5'} sx={{p:2}}>{checkstatus === 4 ? 'Выполненные':'Отклоненные'} анкеты ({cv.filter((e)=>e.status === checkstatus).length})</Typography>
                </Box>
                <Box sx={{ flexGrow: 0, p:2 }}>
                    <Select size={'small'}
                            value={activeProject}
                            onChange={(e)=>{setActiveProject(e.target.value)}}
                            sx={{width: '200px'}}
                    >
                        {projects.map((i,e)=><MenuItem key={'proj_'+e} value={i.shortname}>{i.name}</MenuItem>)}
                    </Select>
                </Box>
            </Box>
        </Paper>
        <Paper sx={{mt:1, maxHeight: '80vh', overflowY: 'auto', backgroundColor: '#4b4b4b'}}>
            {cv.filter((e)=>e.status === checkstatus).sort((a,b)=>a.last_actions > b.last_actions ? 1 : -1).slice((page-1)*15,((page-1)*15)+15).map((i,e)=>{
                let d = {}
                try {
                    d = JSON.parse(i.data_cv.replace(/\\\(/,'(').replace(/\\'/,"'").replace(/\\#/,'#'));
                }catch{
                    d={nickname: '---НЕ ОПРЕДЕЛЯЕМО---'}
                }
                return <Accordion expanded={expanded === `panel${e}`}
                                  onChange={handleChange(`panel${e}`, i)}
                                  key={`cv_${e}`}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '100px', flexShrink: 0 }}>
                            #{i.id}
                        </Typography>
                        <Typography sx={{ width: '20%', flexShrink:0, color: 'yellow' }} dangerouslySetInnerHTML={{__html:d.nickname}}></Typography>
                        <Typography sx={{ width: '20%', flexShrink:0 }}>{resolveVacName(i.vacancy_id)}</Typography>
                        <Typography sx={{ width: '20%', flexShrink:0 }}>Подана {moment(i.create_date).format('DD.MM.YYYY HH:mm')}</Typography>
                        <Typography sx={{ width: '20%', flexShrink:0 }}>Завершена {moment(i.last_actions).format('DD.MM.YYYY HH:mm')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {resolveCvDetails(i)}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            })}
            <Pagination count={Math.ceil(cv.filter((e)=>e.status === checkstatus).length/15)} onChange={(e,v)=>{setPage(v)}}/>
        </Paper>
    </div>
}

export default ListCvs