import settings from "../package.json";

const setApiErrorState = msg => {
    localStorage.setItem('error_state', msg)
    localStorage.setItem('last_error_check', new Date().getTime())
}
const checkApiErrorState = () => {
    return localStorage.getItem('error_state') === null
}

const clearApiErrorState = () => {
    localStorage.removeItem('error_state');
    localStorage.removeItem('last_error_check');
}

const getApi = async path => {
    let rr = localStorage.getItem("access_token");
    clearApiErrorState();
    if(rr !== "undefined" && path !== "auth/") {
        let res = await fetch(settings.api_endpoint + '/' + path, {
            headers: {Authorization: "Bearer " + rr}
        }).catch((err)=>{console.log(err); setApiErrorState(err)});
        if(checkApiErrorState()) {
            let result = await res;
            if (result.status === 403) {
                localStorage.clear()
                window.location.href = "/"
            }
            return await result.json();
        }
        else{
            return null;
        }
    }
    if(rr === "undefined"){
        localStorage.clear()
        window.location.href="/"
    }
}
const postApi = async (path, data) => {
    let rr = localStorage.getItem("access_token");
    clearApiErrorState();
    if((rr !== "undefined" || path === "auth/")) {
        let res = await fetch(settings.api_endpoint + "/" + path, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {Authorization: "Bearer " + rr, "Content-Type": 'application/json'}
        }).catch((err)=>{setApiErrorState(err)});
        if(checkApiErrorState()) {
            let result = await res;
            if (result.status === 403) {
                localStorage.clear()
                window.location.href = "/"
            }
            return await result.json();
        }
        else{
            return null;
        }
    }
}
export default getApi;
export {postApi};